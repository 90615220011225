import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  editor: {
    position: 'relative'
  },
  dropZone: {
    padding: '0px',
    height: '300px',
    width: '300px',
    '& .mantine-Dropzone-inner': {
      dispay: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      '& svg': {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        marginBottom: '10px'
      }
    }
  },

  text: {
    color: theme.colors.gray[6]
  },

  range: {
    width: '250px'
  },

  slider: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '300px',
    paddingTop: '20px'
  },

  hint: {
    display: 'flex',
    position: 'absolute',
    top: '220px',
    textAlign: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
    borderRadius: '8px',
    width: '70%',
    color: 'white',
    fontSize: '14px'
  }
}));
