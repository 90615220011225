import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  content: {
    marginTop: theme.spacing.sm,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%'
  },

  group: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing.md
  },

  progressText: {
    fontSize: '12px',
    color: theme.colors.brandGray[0],
    fontWeight: 600
  },

  progress: {
    height: '6px'
  },

  progressContainer: {
    width: '100%',
    marginTop: theme.spacing.xs
  }
}));
