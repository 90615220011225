import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  title: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'row',
    margin: 0,
    padding: 0
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    // justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: theme.spacing.sm
  }
}));
