import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginLeft: theme.spacing.sm
  },
  text: {
    wordBreak: 'break-word',
    span: {
      fontWeight: 'bold',
      display: 'block'
    }
  }
}));
