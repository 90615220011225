import React, { useEffect } from 'react';
import { Box, Button, SimpleGrid, TextInput, UnstyledButton } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { CalendarIcon } from '@assets/icons';
import { useStyles } from '@/containers/pages/filials/edit/styles';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { IInvitationLink, ILinkInviteFormData } from '@/entities/admin-app/filials';

interface IFormLinkProps {
  handleChangeLink: (values: ILinkInviteFormData) => Promise<void>;
  invitationLink?: IInvitationLink;
  opened: boolean;
  handleClose: () => void;
  isEditing: boolean;
  isFetching: boolean;
}
export const LinkInviteForm = ({
  handleChangeLink,
  invitationLink,
  isEditing,
  handleClose
}: IFormLinkProps) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const validateSchema = z.object({
    name: z
      .string()
      .trim()
      .min(2, { message: t('validation.enterLinkName') })
      .max(20, { message: t('validation.maxLengthSymbolsWithCount.symbols', { count: 20 }) }),
    date: z.date()
  });
  const form = useForm<ILinkInviteFormData>({
    initialValues: {
      name: '',
      date: new Date()
    },
    validateInputOnChange: true,
    validate: (values: ILinkInviteFormData): FormErrors => getValidateErrors(validateSchema, values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    if (invitationLink) {
      const values = {
        name: invitationLink.Name,
        date: new Date(invitationLink.ExpiredAt) || new Date()
      };
      form.setValues(values);
      form.resetDirty(values);
    }
  }, [invitationLink]);

  const handleSubmit = async (values: ILinkInviteFormData) => {
    try {
      await handleChangeLink({ name: values?.name?.trim(), date: values.date });
      form.reset();
    } catch (error) {
      console.log('error ---> ', error);
    }
  };

  return (
    <form className={classes.modalRoot} onSubmit={form.onSubmit(handleSubmit)}>
      <Box className={classes.modalContainer} sx={{ paddingBottom: '24px' }}>
        <SimpleGrid cols={1} w="100%">
          <TextInput
            className={classes.textFieldInput}
            label={t('filials.inviteLinks.linkName')}
            withAsterisk
            {...form.getInputProps('name')}
          />
          <DatePickerInput
            locale={i18n.language}
            className={classes.dateFieldInput}
            rightSection={<CalendarIcon />}
            styles={{ rightSection: { pointerEvents: 'none' } }}
            minDate={new Date()}
            label={t('filials.inviteLinks.validPeriod')}
            popoverProps={{ withinPortal: true, zIndex: 1400 }}
            {...form.getInputProps('date')}
          />
        </SimpleGrid>
      </Box>

      <Box className={classes.modalFooter}>
        <Button w="49%" type="submit" disabled={isDisabled || !form.isValid()}>
          {t(`${isEditing ? 'save' : 'create'}`)}
        </Button>
        <UnstyledButton w="49%" className={classes.modalCancelBtn} onClick={() => handleClose()}>
          {t('cancel')}
        </UnstyledButton>
      </Box>
    </form>
  );
};
