import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  itemBox: {
    backgroundColor: theme.white,
    borderRadius: '8px'
  },

  item: {
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    fontSize: theme.fontSizes.sm,
    backgroundColor: theme.white,
    borderRadius: '8px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    width: '100%',
    cursor: 'pointer'
  },

  itemActive: {
    fontWeight: 600
  }
}));
