import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/utils/customBaseQuery';

import {
  DefaultFilter,
  DefaultFiltersResponse,
  IAuthAction,
  IDiskSpaceFilter,
  IUserActivityFilter
} from '@/entities/admin-app/statistics';

import { IFullResponse, IQueryTable, ISimpleFiltersQuery, IQueryUsersTable } from '@/types/api';
import { IUsersInitialFilters } from '@/types/enums/users';

import { BASE_URL } from '../constants';
import { IFilterItemsRequest, IFilterItemsResponse } from '@/entities/admin-app/roles';

const tagTypes = ['Statistics', 'Filters', 'StatisticsList'];

export const statisticsApi = createApi({
  reducerPath: 'api/statistics',
  baseQuery: customBaseQuery(`${BASE_URL}/v2/`),

  refetchOnFocus: false,
  tagTypes: tagTypes,
  endpoints: (build) => ({
    getStatistics: build.query({
      query: (payload?: IQueryTable<IUsersInitialFilters>) => ({
        url: `Data`,
        body: {
          ...payload
        },
        method: 'post'
      })
    }),
    getAuthActionsHistory: build.query<IAuthAction, ISimpleFiltersQuery>({
      query: (payload) => ({
        url: `usershistory/authactions`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<any>) => response.Response.Data
    }),
    getDefaultAuthActionsFilters: build.query<DefaultFilter[], number>({
      query: (count: number) => ({
        url: `usershistory/filters/default?count=${count}`,
        method: 'get'
      }),
      transformResponse: (response: IFullResponse<DefaultFiltersResponse>) =>
        response.Response.Data.DefaultFilters
    }),
    getAuthActionsFilter: build.query<IFilterItemsResponse, IFilterItemsRequest>({
      query: (payload: IFilterItemsRequest) => ({
        url: `usershistory/filter`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<IFilterItemsResponse>) => {
        const { Data: data } = response.Response;
        data.FilterItems.forEach((item) => {
          delete item.Count;
        });
        return data;
      }
    }),
    getDiskSpace: build.query<IDiskSpaceFilter, IQueryUsersTable>({
      query: (payload) => ({
        url: `statistics/userspace`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<any>) => response.Response.Data
    }),
    getUserActivity: build.query<IUserActivityFilter, IQueryUsersTable>({
      query: (payload) => ({
        url: `statistics/useractivity`,
        body: {
          ...payload
        },
        method: 'post'
      }),
      transformResponse: (response: IFullResponse<any>) => response.Response.Data
    })
  })
});

export const {
  useGetStatisticsQuery,
  useGetAuthActionsHistoryQuery,
  useGetDefaultAuthActionsFiltersQuery,
  useGetAuthActionsFilterQuery,
  useGetDiskSpaceQuery,
  useLazyGetDiskSpaceQuery,
  useGetUserActivityQuery,
  useLazyGetUserActivityQuery
} = statisticsApi;
