import { FC } from 'react';
import { Group, UnstyledButton, Text, Center } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons';
import { useStyles } from './styles';

interface IThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  width?: string;
  hideSort?: boolean;
  hideColumn?: boolean;
}

export const Th: FC<IThProps> = ({
  children,
  reversed,
  sorted,
  onSort,
  width = 'auto',
  hideSort = true,
  hideColumn = false
}) => {
  const { classes, cx } = useStyles({ hideSort });
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;

  return (
    <th
      className={cx(classes.th, { [classes.hideColumn]: hideColumn })}
      style={{ width, minWidth: width }}
    >
      {(children || !hideSort) && (
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart">
            <Text weight={400} size="sm" color="gray.6" fw={400}>
              {children}
            </Text>
            {hideSort || (
              <Center className={classes.icon}>
                <Icon size={14} stroke={1.5} />
              </Center>
            )}
          </Group>
        </UnstyledButton>
      )}
    </th>
  );
};
