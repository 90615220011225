import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: `calc(100% - 580px)`,
    backgroundColor: theme.colors.backgroundGray[0],
    padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`,
    margin: '0 auto',
    [theme.fn.smallerThan('lg')]: {
      width: '100%'
    },
    [theme.fn.smallerThan('md')]: {
      padding: '16px'
    },
    [theme.fn.largerThan('md')]: {
      marginRight: 290
    },
    [theme.fn.largerThan('lg')]: {
      marginLeft: 290
    }
  },

  fakeNavbar: {
    width: '290px',
    [theme.fn.smallerThan('lg')]: {
      display: 'none'
    }
  },
  simpleNavbar: {
    position: 'fixed',
    right: 0,
    left: 'auto',
    backgroundColor: theme.colors.backgroundGray[0],
    '@media (max-width: 992px)': {
      display: 'none',
      width: '0'
    }
  },

  errorContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.colors.backgroundGray[0],
    height: `calc(100vh - 80px)`
  },

  box: {
    padding: '40px 32px 32px',
    [theme.fn.smallerThan('xs')]: {
      padding: '16px'
    },
    margin: '100px  auto auto auto',
    width: '540px',
    maxWidth: `calc(100% - 20px)`
  }
}));
