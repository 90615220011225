import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    padding: '8px 24px 16px',
    borderTop: '1px solid',
    borderColor: theme.colors.backgroundGray[6],
    backgroundColor: theme.colors.backgroundGray[0],
    borderRadius: '0 0 8px 8px'
  }
}));
