import { IAuditItem } from '@/types';
import { IRowData } from '@components/simple-table/types';
import { deviceTypeToString } from '../../../../../../utils';
import { IconBrowser, IconDeviceLaptop, IconDevices, IconMapPin } from '@tabler/icons';

export const authActionsMenu = (item: IRowData | undefined): IAuditItem[] => {
  return [
    {
      icon: IconBrowser,
      label: `${item?.Browser === 'Other' ? '-' : item?.Browser}`
    },
    { icon: IconDevices, label: `${deviceTypeToString(item?.DeviceType)}` },
    { icon: IconMapPin, label: `${item?.Ip}` },
    { icon: IconDeviceLaptop, label: `${item?.Os === 'Other' ? '-' : item?.Os}` }
  ];
};
