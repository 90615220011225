import React, { useEffect } from 'react';
import { Box, LoadingOverlay, Table, Text } from '@mantine/core';
import { DriveInfoPanelsEnum } from '@/types/enums/modules';
import { TableHeadList } from '@/containers/pages/filials/edit/components/tabs/disk-space/components/table/head';
import { TableRowList } from '@/containers/pages/filials/edit/components/tabs/disk-space/components/table/row';
import { IRowData } from '@components/simple-table/types';
import { useGetFilialQuotaColumns } from '@/containers/pages/filials/edit/components/tabs/disk-space/hooks/useGetFilialQuotaColumns';
import { isSaaS } from '@/lib/utils/access';
import { useDisclosure } from '@mantine/hooks';
import { useAppSelector } from '@hooks/redux/redux';
import { useGetFilialQuotaQuery } from '@/entities/admin-app/quotas/api';
import { IFilialsInfoCommon } from '@/entities/admin-app/filials';
import { useActions } from '@hooks/redux/action';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/modal';
import { FilialQuotaModal } from '../quota-modal/filialQuotaModal';
import { useStyles } from '@/containers/pages/filials/edit/styles';

interface IFilialQuotaProps {
  filial: IFilialsInfoCommon;
}
export const FilialQuota = ({ filial }: IFilialQuotaProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { Settings } = useAppSelector((state) => state.userInfo);
  const [opened, { close, open }] = useDisclosure(false);
  const { setFilialQuota, setQuotasFilters } = useActions();

  const { filialQuota } = useAppSelector((state) => state.quotas);

  const { data, isLoading } = useGetFilialQuotaQuery(filial?.Id);

  useEffect(() => {
    if (data) {
      setFilialQuota(data);
    }
  }, [data]);
  const openFilialQuotaModal = () => {
    open();
  };
  const onEditQuota = async () => {
    setQuotasFilters({
      Count: 10,
      Offset: 0
    });
  };

  const { filiaQuotaColumns } = useGetFilialQuotaColumns({
    isSaaS: isSaaS(Settings),
    openFilialQuotaModal: openFilialQuotaModal
  });

  return (
    <Box className={classes.section} data-section={DriveInfoPanelsEnum.filialQuota}>
      <Text className={classes.title}>{t('filials.diskSpace.filialQuota')}</Text>
      <Box className={classes.container} data-section={DriveInfoPanelsEnum.filialQuota}>
        <Table horizontalSpacing="md" verticalSpacing="xs" mt="sm">
          <thead>
            <TableHeadList columns={filiaQuotaColumns} />
          </thead>
          <tbody>
            <TableRowList rows={[filialQuota as IRowData]} columns={filiaQuotaColumns} />
          </tbody>
        </Table>
      </Box>
      <LoadingOverlay visible={isLoading} />
      <Modal
        size="450px"
        opened={opened}
        title={t('filials.diskSpace.filialQuotaEdit')}
        onClose={close}
      >
        <FilialQuotaModal
          onEditQuota={onEditQuota}
          isOpened={opened}
          onClose={close}
          quota={filialQuota}
        />
      </Modal>
    </Box>
  );
};
