import React, { FC, useState } from 'react';
import { Box, Loader, Table, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { Th } from './th';
import { Tr } from './tr';
import { IColumnData, IRowData, ISimpleTableProps } from './types';

type IPaginatedTableProps = Omit<ISimpleTableProps, 'getNextData' | 'setRowData'>;

export const PaginatedTable: FC<IPaginatedTableProps> = ({
  rowData,
  columns,
  search,
  minWidth,
  height,
  width,
  selection,
  error,
  isLoading,
  sort,
  setSort
}) => {
  const { classes, cx } = useStyles();
  const [scrolled] = useState(false);
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { t } = useTranslation();

  const setSorting = (field: keyof IRowData) => {
    if (setSort) {
      setSort({
        SortOrder: field === sort?.SortField ? !sort.SortOrder : false,
        SortField: field
      });
    }
  };

  const rows = rowData?.map((row: IRowData, index: number) => {
    return <Tr key={index} columns={columns} row={row} selection={selection} />;
  });
  const columnsRender = columns.map((col: IColumnData) => (
    <Th
      key={col.dataIndex}
      width={col.width}
      sorted={sort?.SortField === col.dataIndex}
      reversed={!!sort?.SortOrder}
      onSort={() => col.title && setSorting(col.dataIndex)}
      hideSort={!col.hasSort}
      hideColumn={col.hideColumn}
    >
      {col.title}
    </Th>
  ));

  return (
    <Box
      sx={{ width, height, display: 'flex', flexDirection: 'column' }}
      className={classes.wrapper}
    >
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        sx={{ width: isMobile ? '800px' : minWidth }}
      >
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>{columnsRender}</tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr className={classes.tr} style={{ height }}>
              <td colSpan={columns.length}>
                <Box className={classes.infiniteScrollMoreData}>
                  <Loader size="sm" />
                </Box>
              </td>
            </tr>
          )}

          {rowData?.length === 0 && search && (
            <tr className={classes.tr} style={{ height }}>
              <td colSpan={columns.length}>
                {error ? (
                  <Box className={classes.error}>
                    {Object.entries(error).map(([key, value]) => (
                      <p key={key}>
                        {key}: <p>{value}</p>
                      </p>
                    ))}
                  </Box>
                ) : (
                  <Text weight={500} align="center" color="gray.6">
                    {t('noData')}
                  </Text>
                )}
              </td>
            </tr>
          )}
          {rowData?.length > 0 && !isLoading && rows}
        </tbody>
      </Table>
      <Box></Box>
    </Box>
  );
};
