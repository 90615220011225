import { useStyles } from './styles';
import { useParams } from 'react-router-dom';
import { Button, Group, Paper, Text } from '@mantine/core';
import { useEffect } from 'react';
import { useLazyConfirmEmailActivationQuery } from '@/entities/admin-app/email/api';
import { LoginWraper } from '@components/login';
import iconSuccess from '@assets/icon-mail-success.svg';
import iconError from '@assets/icon-mail-error.svg';
import { useTranslation } from 'react-i18next';

export const ConfirmEmail = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { code = '' } = useParams();

  const [confirmEmailActivation, { isSuccess }] = useLazyConfirmEmailActivationQuery();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await confirmEmailActivation({ Code: code });
      } catch (error) {
        console.error('Error while making API request:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <LoginWraper>
      <Paper className={classes.responseContent}>
        {isSuccess ? (
          <>
            <img src={iconSuccess} alt="icon" className={classes.icon} />
            <Text className={classes.desc} align="center">
              {t('profile.emailConfirmed')}
            </Text>
          </>
        ) : (
          <>
            <img src={iconError} alt="icon" className={classes.icon} />
            <Text className={classes.desc} align="center">
              {t('profile.emailError')}
            </Text>
          </>
        )}

        <Group position="center" w="100%">
          <Button w="100%" className={classes.responsiveInput} component="a" size="lg" href="/">
            {t('goHome')}
          </Button>
        </Group>
      </Paper>
    </LoginWraper>
  );
};
