import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  modal: {
    padding: `${theme.spacing.md} !important`
  },
  header: {
    display: 'none'
  }
}));
