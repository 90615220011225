import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  errorContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white'
  },

  icon: {
    marginTop: '20px'
  },

  text: {
    textAlign: 'center',
    color: theme.colors.gray[6],
    lineHeight: '22px',
    marginBottom: '5px'
  }
}));
