import React, { FC, useCallback, useEffect } from 'react';
import { Box, Flex, Text, SimpleGrid, LoadingOverlay } from '@mantine/core';
import { useAppSelector } from '@hooks/redux/redux';
import { useStyles } from '../../../styles';
import { useLazyGetEmailServerQuery } from '@/entities/admin-app/email/api';
import { MailServerItem } from '@/containers/pages/filials/edit/components/tabs/mail-servers/mailServerItem';
import { CreateMailServer } from '@/containers/pages/filials/edit/components/tabs/mail-servers/createMailServer';
import { useTranslation } from 'react-i18next';

const MailServersPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { filial } = useAppSelector((state) => state.filials);

  const [getEmailServerList, { data: EmailServerList, isFetching }] = useLazyGetEmailServerQuery();

  const handleChange = useCallback(() => {
    filial?.Id && getEmailServerList({ Id: filial?.Id });
  }, [filial]);

  useEffect(() => {
    if (!filial) {
      return;
    }
    if (filial.Id) {
      getEmailServerList({ Id: filial?.Id });
    }
  }, [filial]);

  return (
    <Box className={classes.container}>
      <Flex className={classes.section} align="flex-start" direction="column">
        <Text className={classes.title}>{t('servers.title')}</Text>
        {filial && EmailServerList?.length
          ? EmailServerList?.map((mailServer: any, index: number) => {
              return (
                <MailServerItem
                  CustomerId={filial?.Id}
                  mailServer={mailServer}
                  handleChange={handleChange}
                  key={index}
                  index={index}
                />
              );
            })
          : null}
        <SimpleGrid cols={1} mt="sm">
          <CreateMailServer CustomerId={filial?.Id || 0} handleChange={handleChange} />
        </SimpleGrid>
        <LoadingOverlay visible={isFetching} overlayBlur={2} />
      </Flex>
    </Box>
  );
};

export default MailServersPage;
