import { FC, PropsWithChildren } from 'react';
import { ActionIcon, Badge, Box, Menu, UnstyledButton } from '@mantine/core';
import { IconBackspace, IconChevronDown } from '@tabler/icons';

import { IFilterMenu } from '@/types/api';
import { IFiltersEnumType } from '@/types/enums';

import { themeColors } from '@/theme/colors';

import { useStyles } from './styles';

interface IFilterBadgeProps extends PropsWithChildren {
  filterMenu: IFilterMenu;
  handleFilterReset: (value: IFiltersEnumType) => void;
  activeIds: number[];
  filterType: IFiltersEnumType;
}

export const FilterBadge: FC<IFilterBadgeProps> = ({
  filterMenu,
  handleFilterReset,
  activeIds,
  filterType,
  children
}) => {
  const { classes } = useStyles();

  return (
    <Menu width="auto" zIndex={400} position="bottom-start">
      <Menu.Target>
        <Badge
          className={classes.filterHeader}
          rightSection={
            activeIds?.length > 0 && (
              <Box className={classes.badgeContainer}>
                <Badge
                  className={
                    filterType === IFiltersEnumType.DateOfCreateFilter
                      ? classes.withoutNumber
                      : classes.badge
                  }
                  variant="filled"
                >
                  {filterType === IFiltersEnumType.DateOfCreateFilter ? ' ' : activeIds.length}
                </Badge>

                <ActionIcon size={24} onClick={() => handleFilterReset(filterMenu.Type)}>
                  <IconBackspace size={16} color={themeColors.brandGray[0]} />
                </ActionIcon>
              </Box>
            )
          }
        >
          <UnstyledButton className={classes.menuButton}>
            {filterMenu?.label} <IconChevronDown size={18} />
          </UnstyledButton>
        </Badge>
      </Menu.Target>
      <Menu.Dropdown className={classes.dropdownContainer}>{children}</Menu.Dropdown>
    </Menu>
  );
};
