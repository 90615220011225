import React, { FC, useEffect } from 'react';
import { Button, Flex, Radio, SimpleGrid, TextInput } from '@mantine/core';
import { useStyles } from '../../styles';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@hooks/redux/redux';
import { z } from 'zod';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { ICommonSettingsEnum, ITeamSettings } from '@/entities/admin-app/commonSettings';
import { useSaveCommonSettings } from '@/containers/pages/commonSettings/hooks/useSaveCommonSettings';
import { CommonSectionTemplate } from '@components/section-template';
import { IconRefresh } from '@tabler/icons';
import { teamSettingsSync } from '@/containers/pages/commonSettings/hooks/TeamSettingsSync';

const IntegrationsPage: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { TeamSettings } = useAppSelector((state) => state.commonSettings);
  const updateSettings = useSaveCommonSettings();
  const { isTeamSync, SyncTeamSettings } = teamSettingsSync();

  const enabledScheme = z.object({
    IsEnabled: z.literal(true),
    Url: z.string().min(1, { message: 'Введите ссылку' })
  });
  const disabledScheme = z.object({
    IsEnabled: z.literal(false),
    Url: z.string().nullable()
  });

  const validateScheme = z.union([enabledScheme, disabledScheme]);

  const form = useForm<ITeamSettings>({
    initialValues: {
      IsEnabled: false,
      Url: ''
    },
    validateInputOnChange: true,
    validate: (values: ITeamSettings): FormErrors => getValidateErrors(validateScheme, values)
  });

  const errors = form.errors;
  const isDirtyForm = form.isDirty();
  const isDisabled = Object.keys(errors).length !== 0 || !isDirtyForm;

  useEffect(() => {
    if (TeamSettings) {
      const formData = {
        Url: TeamSettings?.Url || '',
        IsEnabled: TeamSettings?.IsEnabled || false
      };

      form.setValues(formData);
      form.resetDirty(formData);
    }
  }, [TeamSettings]);
  const handleSubmit = async (values: ITeamSettings) => {
    await updateSettings(ICommonSettingsEnum.Commands, {
      TeamSettings: {
        IsEnabled: values.IsEnabled,
        Url: values.IsEnabled ? values.Url : TeamSettings.Url
      }
    });
  };

  const handleChange = () => {
    if (!form.values.Url?.length) {
      form.setValues({ IsEnabled: false, Url: '' });
    } else {
      form.setValues({ IsEnabled: false });
    }
  };

  const handleRefresh = async () => {
    if (TeamSettings) {
      await SyncTeamSettings();
    }
  };

  return (
    <CommonSectionTemplate title={t('team')}>
      <SimpleGrid cols={2} w="100%" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio
            className={classes.radio}
            size="xs"
            checked={!form?.values?.IsEnabled}
            onChange={() => handleChange()}
            label={t('disable')}
          />
          <Radio
            className={classes.radio}
            size="xs"
            mb="sm"
            checked={form?.values?.IsEnabled}
            onChange={() => form.setValues({ IsEnabled: true })}
            label={t('enable')}
          />

          {form?.values?.IsEnabled ? (
            <TextInput
              className={classes.textFieldInput}
              label={t('link')}
              withAsterisk
              {...form.getInputProps(`Url`)}
            />
          ) : null}

          <Flex className={classes.sectionControls} w="100%">
            <Button
              disabled={isDisabled}
              className={classes.submitButton}
              size="sm"
              radius={8}
              // mr="sm"
              type="submit"
              mt="md"
              // style={{ padding: '0 20px' }}
            >
              {t('save')}
            </Button>

            {TeamSettings?.IsEnabled ? (
              <Button
                size="sm"
                radius={8}
                color="green"
                mt="md"
                rightIcon={<IconRefresh size={24} className={isTeamSync ? classes.rotate : ''} />}
                onClick={() => handleRefresh()}
                disabled={isTeamSync}
              >
                {t('sync')}
              </Button>
            ) : null}
          </Flex>
        </form>
      </SimpleGrid>
    </CommonSectionTemplate>
  );
};

export default IntegrationsPage;
