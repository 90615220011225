import { useState, useEffect, useMemo } from 'react';
import { SelectItem } from '@mantine/core';

import { useGetFilialsQuery } from '@/entities/admin-app/filials/api';
import { IFilialsTableData } from '@/entities/admin-app/filials/types';

export interface FilterProps {
  Count: number;
  Offset: number;
  Query: string;
  ForceCustomerId: number | undefined;
  SkipCustomers?: number[];
}

export default function useLoadMore(ForceId?: number, SkipCustomers?: number[]) {
  const [filials, setFilials] = useState<IFilialsTableData[]>([]);
  const [filters, setFilters] = useState<FilterProps>({
    Count: 10,
    Offset: 0,
    Query: '',
    ForceCustomerId: undefined,
    SkipCustomers: []
  });
  const { data, isLoading, isFetching, isError } = useGetFilialsQuery({
    ...filters
  });

  const total = data?.TotalCount || 0;

  const loadMore = () => {
    if (total - filters.Offset > 0) {
      setFilters({
        ...filters,
        Offset: filters.Offset + 10
      });
    }
  };

  useEffect(() => {
    if (data) {
      setFilials(filters.Offset > 0 ? filials.concat(data.Items) : data.Items);
    }
  }, [data]);

  useEffect(() => {
    if (ForceId && ForceId !== filters.ForceCustomerId) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ForceCustomerId: ForceId,
        Offset: 0
      }));
    }
  }, [ForceId]);

  useEffect(() => {
    if (SkipCustomers && SkipCustomers?.length) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        SkipCustomers: [...SkipCustomers],
        Offset: 0
      }));
    }
  }, [SkipCustomers]);

  const list = useMemo(
    () =>
      filials?.map(
        (Item: IFilialsTableData) =>
          ({ label: String(Item.Title), value: Item.Id.toString() } as SelectItem)
      ),
    [filials]
  );

  return { list, total, isLoading, isFetching, isError, loadMore };
}
