import { FC } from 'react';
import { Box, Text } from '@mantine/core';
import { useStyles } from './styles';
import { IPosition } from '@/entities/admin-app/user';
import { IRowData } from '@components/simple-table/types';
import { useTranslation } from 'react-i18next';

interface IParentUserNames {
  item: IRowData;
}

export const ParentUserNames: FC<IParentUserNames> = ({ item }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.content}>
      {item?.some((position: IPosition) => position?.ParentUserName) ? (
        item.slice(0, 3).map((position: IPosition, index: number) => (
          <Box key={index} maw="200px">
            <Text size="xs" weight={400} className={classes.text}>
              <span>{position?.ParentUserName}</span>
            </Text>
          </Box>
        ))
      ) : (
        <Text size="xs" weight={400}>
          {t('users.noManager')}
        </Text>
      )}
    </Box>
  );
};
