import { SyntheticEvent, FC } from 'react';
import { Box, Radio, Text } from '@mantine/core';
import { useStyles } from './styles';

interface IGroupItemProps {
  checked?: boolean;
  defaultChecked?: boolean;
  label: string;
  handleChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  handleClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
  value: number;
  counter?: number;
  withRadioButtonbox?: boolean;
  selectedIdGroup?: number | null;
}

export const GroupItem: FC<IGroupItemProps> = ({
  defaultChecked,
  checked,
  label,
  value,
  counter,
  handleChange,
  handleClick,
  withRadioButtonbox = false,
  selectedIdGroup
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.root, {
        [classes.selectedGroup]: Boolean(selectedIdGroup) && value === selectedIdGroup
      })}
    >
      {withRadioButtonbox ? (
        <Radio
          className={classes.radio}
          size="xs"
          data-value={value}
          value={value}
          checked={Boolean(checked)}
          onChange={handleChange}
          defaultChecked={defaultChecked ?? undefined}
          label={
            <Box className={classes.checkboxContainer}>
              <Text className={classes.checkbox}>{label}</Text>
              <Text className={classes.counter}>{counter && counter}</Text>
            </Box>
          }
        />
      ) : (
        <Box className={classes.checkboxContainer} data-value={value} onClick={handleClick}>
          <Text className={classes.checkbox}>{label}</Text>
          <Text className={classes.counter}>{counter && counter}</Text>
        </Box>
      )}
    </Box>
  );
};
