import { Button, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons';
import { useStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { themeColors } from '@/theme/colors';
import { MailServerForm } from './components/serverForm';

interface ICreateMailServerProps {
  CustomerId: number;
  handleChange?: () => void;
}
export const CreateMailServer = ({ CustomerId, handleChange }: ICreateMailServerProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Button
        className={classes.addButton}
        size="sm"
        variant="outline"
        rightIcon={<IconPlus color={themeColors.brandGray[0]} />}
        onClick={() => open()}
      >
        <Text>{t('servers.create')}</Text>
      </Button>
      {opened && (
        <MailServerForm
          CustomerId={CustomerId}
          handleChange={handleChange}
          opened={opened}
          handleClose={close}
          isEditing={false}
        />
      )}
    </>
  );
};
