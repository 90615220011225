import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '12px 24px 24px',
    gap: theme.spacing.sm
  },
  switchBody: {
    justifyContent: 'space-between'
  },
  passwordRoot: {
    width: '100%'
  },
  cancelButton: {
    border: 'none',
    backgroundColor: theme.colors.brandGray[1],

    '&:hover': {
      border: `1px solid ${theme.colors.brandGray[1]}`
    }
  }
}));
