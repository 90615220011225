import {
  ActionIcon,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  PasswordInput,
  Text,
  Textarea,
  TextInput
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { CalendarIcon, RefreshIcon } from '../../../../../../../../assets/icons';
import { Controls } from '../../../../../../../../components/modal/components/controls';
import { Toggle } from '../toggle';

import { useStyles } from './styles';

type Props = {
  id?: string;
};

export const LinkForm = ({ id }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.container}>
        <TextInput
          placeholder="Новая ссылка"
          radius="md"
          label={
            <Text fz="xs" color="#667085">
              Название ссылки
            </Text>
          }
        />
        <DatePickerInput
          placeholder="Выбрать дату"
          radius="md"
          rightSectionWidth={60}
          label={
            <Text fz="xs" color="#667085">
              Срок действия, до
            </Text>
          }
          rightSection={<CalendarIcon />}
        />
        <Checkbox
          label={
            <Text>
              Добавить со статусом{' '}
              <Text span fw={600}>
                Гость
              </Text>
            </Text>
          }
        />
        <Divider my="sm" />
        <Toggle
          classNames={{ body: classes.switchBody }}
          labelPosition="left"
          label="Включить доступ по паролю"
        />
        <Flex align="center" gap="sm">
          <PasswordInput
            placeholder="Введите пароль"
            radius="md"
            classNames={{ root: classes.passwordRoot }}
          />
          <ActionIcon>
            <RefreshIcon />
          </ActionIcon>
        </Flex>
        <Divider my="sm" />
        <Autocomplete
          placeholder="Выбрать группу"
          radius="md"
          label={
            <Text fz="xs" color="#667085">
              Добавить пользователей к группе
            </Text>
          }
          data={[]}
        />
        <Divider my="sm" />
        <Textarea
          placeholder="https://r7-demo.ru/confirm.aspx?type=LinkInvite&key=410086721052.JMLRWU381BB4UPN0X3RDARRXSWOFCGC3KMIVWYP2BU&uid=8770814e-4d08-459b-a87a-d39a3eaab4ff&emplType=1"
          radius="md"
          minRows={4}
        />
        <Toggle label="Сокращенная ссылка" />
      </div>
      <Controls>
        {id ? (
          <Group mt="md" grow>
            <Button radius="md">Сохранить</Button>
            <Button
              className={classes.cancelButton}
              variant="default"
              color="brandGray.1"
              radius="md"
            >
              Отмена
            </Button>
          </Group>
        ) : (
          <Button fullWidth mt="md" radius="md">
            Сохранить и скопировать
          </Button>
        )}
      </Controls>
    </>
  );
};
