import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '@hooks/redux/redux';
import { isAdmin, isSaaS } from '@/lib/utils/access';
import { ROUTES } from './constants';

const MainPageRote = () => {
  const location = useLocation();
  const currentUser = useAppSelector((state) => state.userInfo.User);
  const { Settings } = useAppSelector((state) => state.userInfo);

  const isFirstLogin = localStorage.getItem('isFirstLogin') === 'true';
  const RedirectToDashboard = isFirstLogin && isSaaS(Settings);
  const RedirectToUsers = location.pathname === '/' && isAdmin(currentUser);

  if (RedirectToDashboard) {
    localStorage.setItem('isFirstLogin', 'false');
  }

  if (!isAdmin(currentUser) || RedirectToDashboard) {
    return <Navigate to={ROUTES.dashboard.fullPath} />;
  } else if (RedirectToUsers) {
    return <Navigate to={ROUTES.users.fullPath} />;
  }

  return <Outlet />;
};

export default MainPageRote;
