import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  popover: {
    padding: theme.spacing.xs,
    width: 'max-content'
  },
  itemMenu: {
    padding: theme.spacing.xs,
    cursor: 'pointer',
    borderRadius: '8px',
    fontSize: '14px',
    gap: 0,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    }
  }
}));
