import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .mantine-DatePickerInput-input': {
      borderRadius: '8px'
    },
    '& .mantine-Popover-dropdown': {
      padding: '16px'
    },
    '& .mantine-DatePickerInput-calendar': {
      width: '255px'
    }
  }
}));
