import React, { FC, useRef, useState, useEffect } from 'react';
import { Box, Flex, Text, Divider, Pagination, LoadingOverlay } from '@mantine/core';

import { PaginatedTable } from '@components/simple-table/PaginatedTable';

import { useActions } from '@hooks/redux/action';
import { useAppSelector } from '@hooks/redux/redux';

import { FilterList } from '../../filter-list';
import { TableHeader } from '../../table-header';
import {
  useGetDiskSpaceQuery,
  useLazyGetDiskSpaceQuery
} from '@/entities/admin-app/statistics/api';
import { useGetFilterListItemsQuery } from '@/entities/admin-app/users/api';
import { useGetDiskSpaceFilters } from './hooks/useGetFilters';
import { useGetColumns } from './hooks/useGetColumns';
import { transformConditionals } from '@/containers/pages/users/utils';
import { useStyles as useCommonStyles } from '../../../styles';
import { useStyles } from './styles';

import { IStatsFilters } from '@/containers/pages/statistics/types';
import { useTranslation } from 'react-i18next';

import {
  convertToFilterConditions,
  transformNumberToSortField,
  transformSortFieldToNumber
} from './utils';
import { CSVLinkComponent } from '../../download-button';
import { formatDataSizeUnits } from '@/lib/utils/format';
import { IDiskSpace } from '@/entities/admin-app/statistics';
import { formatDate } from '@/lib/utils/date';
import { NoDataText } from '@/components/not-found/components/no-data-text';
import { initialStatisticFilter, listSizes } from '@/entities/admin-app/statistics/consts';

const pageSizeOptions = listSizes.map((page) => ({
  value: page.toString(),
  label: page.toString()
}));

const DiskSpace: FC = () => {
  const { t } = useTranslation();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const {
    Total,
    TotalCount,
    filters: actionsFilters,
    Items: actionsDiskSpace
  } = useAppSelector((state) => state.statistics.diskSpace);

  const initialActiveFilters = actionsFilters.FilterConditions
    ? transformConditionals(actionsFilters.FilterConditions)
    : {};

  const [activeFilters, setActiveFilters] = useState<IStatsFilters>(
    initialActiveFilters as IStatsFilters
  );

  const [rowData, setRowData] = useState<any[]>(actionsDiskSpace);
  const [pageSize, setPageSize] = useState<number>(actionsFilters.Count || listSizes[0]);
  const [activePage, setActivePage] = useState(1);
  const { columns } = useGetColumns();
  const scrollRef = useRef<any>(null);
  const { setDiskSpace, setDiskSpaceFilters } = useActions();
  const { filtersMenuList } = useGetDiskSpaceFilters();

  const [sortParams, setSortParams] = useState<{
    SortOrder: number;
    SortField: number;
  }>({
    SortOrder: actionsFilters.SortOrder,
    SortField: actionsFilters.SortField
  });

  const { data, error, isLoading, isSuccess } = useGetDiskSpaceQuery({
    FilterConditions: actionsFilters.FilterConditions ?? [],
    Query: actionsFilters.Query,
    Count: pageSize,
    Offset: actionsFilters.Offset,
    SortOrder: actionsFilters.SortOrder,
    SortField: actionsFilters.SortField
  });

  const fileName = `disk-space-${formatDate(new Date())}`;

  const [getDiskSpace, { data: csvData, isLoading: csvIsLoading }] = useLazyGetDiskSpaceQuery();

  const formatCsvData = (csvData: IDiskSpace[] | undefined) => {
    return csvData?.map((element: IDiskSpace) => {
      const formatedSpace = formatDataSizeUnits(element.Space);
      return {
        ...element,
        Space: formatedSpace
      };
    });
  };

  useEffect(() => {
    if (data) {
      setDiskSpace(data);
    }
  }, [data]);

  const handleFilterChange = (updatedFilters: IStatsFilters) => {
    setActiveFilters(updatedFilters);
    scrollRef?.current?.el?.scrollTo(0, 0);
  };

  const defaultTotalValue = {
    Name: '',
    Space: 0,
    DocsCount: 0,
    FilesCount: 0
  };

  const finalRowData = [Total || defaultTotalValue].concat(rowData);

  const handleResetFilters = () => {
    setPageSize(listSizes[0]);
    setActivePage(1);
    setDiskSpaceFilters(initialStatisticFilter);
  };

  useEffect(() => {
    return () => {
      handleResetFilters();
    };
  }, []);

  useEffect(() => {
    setDiskSpaceFilters({
      ...actionsFilters,
      FilterConditions: convertToFilterConditions(activeFilters),
      Offset: 0
    });
    setActivePage(1);
  }, [activeFilters]);

  useEffect(() => {
    setRowData(actionsDiskSpace ?? []);
  }, [actionsDiskSpace]);

  useEffect(() => {
    setDiskSpaceFilters({
      ...actionsFilters,
      Offset: 0,
      SortField: sortParams?.SortField,
      SortOrder: sortParams?.SortOrder
    });
    setActivePage(1);
  }, [sortParams]);

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    setActivePage(1);
    setDiskSpaceFilters({
      ...actionsFilters,
      Offset: 0,
      Count: value
    });
  };

  const handleChangeActivePage = (value: number) => {
    setActivePage(value);
    setDiskSpaceFilters({
      ...actionsFilters,
      Offset: (value >= 1 ? value - 1 : 0) * pageSize
    });
  };

  const handleSortTable = (payload: any) => {
    setSortParams({
      SortOrder: payload.SortOrder === true ? 1 : 0,
      SortField: transformSortFieldToNumber(payload.SortField)
    });
  };

  return (
    <Box className={classes.wrapper} mr="md">
      <Flex direction="column" className={classes.container} my="md" px="md" py="md">
        <Text color="#101828" fw="600">
          {t('statistics.disk_space')}
        </Text>
        <Box className={classes.listWrapper}>
          <FilterList
            filtersMenuList={filtersMenuList}
            activeFilters={activeFilters}
            onFilterChange={handleFilterChange}
            onFilterReset={setActiveFilters}
            onLoadData={useGetFilterListItemsQuery}
          />
        </Box>
        <Divider className={classes.divider} mx="-md" mb="sm" />
        {finalRowData.length && TotalCount ? (
          <>
            <TableHeader
              handleChangePageSize={handleChangePageSize}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              isLoading={isLoading}
              startOffset={actionsFilters.Offset + 1}
              endOffset={Math.min(actionsFilters.Offset + pageSize, TotalCount)}
              totalCount={TotalCount}
              showButton={true}
              buttonComponent={
                <CSVLinkComponent
                  text={t('statistics.export')}
                  filename={fileName}
                  columns={columns}
                  csvData={formatCsvData(csvData?.Items)}
                  actionsFilters={{
                    ...actionsFilters,
                    Offset: 0,
                    Count: TotalCount
                  }}
                  getCsvData={getDiskSpace}
                  isLoading={csvIsLoading}
                />
              }
            />
            <Box mt="lg">
              <PaginatedTable
                allDataLength={TotalCount}
                rowData={finalRowData}
                columns={columns}
                height="100%"
                width="100%"
                scrollRef={scrollRef}
                error={error}
                isLoading={isLoading}
                pinnedRows={[0]}
                sort={{
                  SortOrder: sortParams.SortOrder !== 0,
                  SortField: transformNumberToSortField(sortParams.SortField)
                }}
                setSort={handleSortTable}
              />
            </Box>
            <Box className={classes.paginateWrapper}>
              {TotalCount > pageSize && (
                <Pagination
                  className={commonClasses.paginate}
                  value={activePage}
                  onChange={handleChangeActivePage}
                  total={Math.ceil(TotalCount / pageSize)}
                  withControls={false}
                  withEdges
                  position="center"
                  size="sm"
                />
              )}
            </Box>
          </>
        ) : (
          isSuccess &&
          !isLoading &&
          !TotalCount && (
            <Flex align="center" justify="center" h="350px">
              <NoDataText />
            </Flex>
          )
        )}
        <LoadingOverlay visible={isLoading} />
      </Flex>
    </Box>
  );
};

export default DiskSpace;
