import { useParams } from 'react-router-dom';
import { Box, LoadingOverlay } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { LoginWraper } from '@/components/login';
import { useStyles } from './styles';
import {
  useLazyCheckCanCreateUserQuery,
  useLazyCheckInvitationLinkCodeQuery
} from '@/entities/admin-app/auth/api';
import { useEffect, useState } from 'react';
import { IPasswordSecurity } from '@/entities/admin-app/filials';
import { InvalidLinkContent } from '@/containers/pages/reser-Password/components/InvalidLinkContent';
import { RegistrationForm } from '@/containers/pages/register/components/RegistrationForm';
import { MessageContainer } from '@/components/message-container';
import iconError from '@assets/icon-cant-register.svg';
import { useAppSelector } from '@/hooks/redux/redux';
import { isSaaS } from '@/lib/utils/access';

export const InviteRegister = () => {
  const { t } = useTranslation();
  const { code = '' } = useParams();
  const { classes } = useStyles();

  const { Settings } = useAppSelector((state) => state.userInfo);
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const [isRequestCompleted, setIsRequestCompleted] = useState(false);
  const [passwordStrengthOptions, setPasswordStrengthOptions] = useState<IPasswordSecurity>({});
  const [checkInvitationLinkCode, { data: checkInvitationLinkData }] =
    useLazyCheckInvitationLinkCodeQuery();
  const [checkCanCreateUser, { data: canCreateUserData, isLoading }] =
    useLazyCheckCanCreateUserQuery();

  useEffect(() => {
    if (checkInvitationLinkData && isSaaS(Settings)) {
      checkCanCreateUser({ Customerid: checkInvitationLinkData.CustomerId });
    }
  }, [checkInvitationLinkData]);

  useEffect(() => {
    checkInvitationLinkCode({ Code: code }).then(() => {
      setIsRequestCompleted(true);
    });
  }, [code, checkInvitationLinkCode]);

  useEffect(() => {
    if (checkInvitationLinkData) {
      setIsCodeValid(checkInvitationLinkData?.IsActive);
      setPasswordStrengthOptions(checkInvitationLinkData);
    }
  }, [checkInvitationLinkData]);

  return (
    <LoginWraper
      title={!isRequestCompleted || !isCodeValid || canCreateUserData ? '' : t('register.title')}
      text={t('')}
    >
      <Box className={classes.controls}>
        {!isRequestCompleted || isLoading ? (
          <LoadingOverlay visible={true} overlayBlur={10} />
        ) : !isCodeValid ? (
          <InvalidLinkContent />
        ) : !canCreateUserData?.IsCanAddUser && isSaaS(Settings) ? (
          <MessageContainer
            icon={iconError}
            description={t('register.cantRegister')}
            buttonText={t('backMain')}
            redirect="/login"
          />
        ) : (
          <RegistrationForm
            passwordStrengthOptions={passwordStrengthOptions}
            invitationData={checkInvitationLinkData}
            invitationCode={code}
          />
        )}
      </Box>
    </LoginWraper>
  );
};
