import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    flexGrow: 1,
    // overflow: 'auto',
    width: `calc(100% - 580px)`,
    backgroundColor: theme.colors.backgroundGray[0],
    padding: `${theme.spacing.md} ${theme.spacing.sm} ${theme.spacing.md} ${theme.spacing.sm}`,
    [theme.fn.smallerThan('lg')]: {
      width: '100%'
    },
    [theme.fn.smallerThan('md')]: {
      padding: '16px'
    },
    [theme.fn.largerThan('lg')]: {
      marginRight: 290
    },
    [theme.fn.largerThan('md')]: {
      marginLeft: 290
    }
  },

  section: {
    height: `calc(100vh - 80px)`
  },

  scrollContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: '8px',
    height: '100%',
    backgroundColor: theme.colors.backgroundGray[0]
  },

  headerMenu: {
    backgroundColor: 'white',
    padding: '10px 0 0 10px',
    [theme.fn.largerThan('md')]: {
      display: 'none'
    }
  },

  headerMenuBurger: {
    div: {
      height: '2px'
    }
  },
  fakeNavbar: {
    width: '290px',
    [theme.fn.smallerThan('lg')]: {
      display: 'none'
    }
  },
  simpleNavbar: {
    backgroundColor: theme.colors.backgroundGray[0],
    position: 'fixed',
    [theme.fn.smallerThan('md')]: {
      alignItems: 'flex-start',
      marginRight: theme.spacing.xs,
      marginLeft: theme.spacing.xs,
      backgroundColor: 'white',
      padding: '0'
    }
  },
  radio: {
    padding: '10px 0',

    '& .mantine-Radio-inner': {
      alignSelf: 'center'
    },

    '& .mantine-Radio-label': {
      fontSize: '14px',
      paddingLeft: theme.spacing.xs
    }
  },

  textFieldInput: {
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px',
      marginBottom: theme.spacing.xs
    },

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    },
    maxWidth: '300px'
  },

  rotate: {
    animation: 'rotate 3s linear infinite',

    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  },

  sectionControls: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column'
    }
  },
  submitButton: {
    marginRight: theme.spacing.sm,
    [theme.fn.smallerThan('xs')]: {
      marginRight: 0,
      maxWidth: '200px'
    }
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #D0D5DD',
    borderRadius: theme.radius.md,
    position: 'relative',
    overflow: 'hidden',
    // height: 80,
    // width: 300,
    // maxWidth: 300,
    // maxHeight: 80,
    // padding: theme.spacing.xs,
    marginBottom: theme.spacing.xs,
    [theme.fn.smallerThan('xs')]: {
      maxWidth: '300px'
    }
  },

  imageOverlay: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: '.3s ease',
    opacity: 0,
    backgroundColor: theme.colors.dark[9],
    textAlign: 'center',
    '&:hover': {
      opacity: '0.7'
    }
  },
  description: {
    fontSize: '12px',
    lineHeight: '17.5px',
    color: theme.colors.brandGray[0],
    fontWeight: 500,
    marginBottom: theme.spacing.xs
  }
}));
