import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatisticsInitialFilters } from '@/types/enums/statistics';
import { IAuthAction, IDiskSpace, IUserActivity } from './types';
import { initialStatisticFilter } from '@/entities/admin-app/statistics/consts';
interface IStatistics {
  files: any[];
  total: number;
  filters: IStatisticsInitialFilters;
  selectedStatisticId: number;
  selected: number[];
  authActionsHistory: {
    TotalCount: number;
    Actions: IAuthAction[];
    filters: IStatisticsInitialFilters;
  };
  diskSpace: {
    Total: IDiskSpace;
    TotalCount: number;
    Items: IDiskSpace[];
    filters: IStatisticsInitialFilters;
  };
  userActivity: {
    Total: IUserActivity;
    TotalCount: number;
    Items: IDiskSpace[];
    filters: IStatisticsInitialFilters;
  };
}

const initialState: IStatistics = {
  files: [],
  total: 0,
  filters: initialStatisticFilter,
  selectedStatisticId: 0, // for show UserInfoMain when selectedUserId !== 0
  selected: [],
  authActionsHistory: {
    TotalCount: 0,
    Actions: [],
    filters: initialStatisticFilter
  },
  diskSpace: {
    Total: {
      Name: '',
      Space: 0,
      DocsCount: 0,
      FilesCount: 0
    },
    TotalCount: 0,
    Items: [],
    filters: initialStatisticFilter
  },
  userActivity: {
    Total: {
      Date: '',
      Visitors: 0,
      Auths: 0,
      Documents: 0,
      Files: 0
    },
    TotalCount: 0,
    Items: [],
    filters: initialStatisticFilter
  }
};

export const StatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics(state, action: PayloadAction<IStatistics>) {
      state.files = action.payload.files;
      state.total = action.payload.total;
    },
    setAuthActionsHistory(state, action: PayloadAction<any>) {
      state.authActionsHistory.Actions = action.payload.Actions;
      state.authActionsHistory.TotalCount = action.payload.TotalCount;
    },
    setAuthActionsFilters(state, action: PayloadAction<IStatisticsInitialFilters>) {
      state.authActionsHistory.filters = action.payload;
    },

    setDiskSpace(state, action: PayloadAction<any>) {
      state.diskSpace.Items = action.payload.Items;
      state.diskSpace.TotalCount = action.payload.TotalCount;
      state.diskSpace.Total = action.payload.Total;
    },
    setDiskSpaceFilters(state, action: PayloadAction<IStatisticsInitialFilters>) {
      state.diskSpace.filters = action.payload;
    },

    setUserActivity(state, action: PayloadAction<any>) {
      state.userActivity.Items = action.payload.Items;
      state.userActivity.TotalCount = action.payload.TotalCount;
      state.userActivity.Total = action.payload.Total;
    },
    setUserActivityFilters(state, action: PayloadAction<IStatisticsInitialFilters>) {
      state.userActivity.filters = action.payload;
    },

    setSelectedAllStatistics(state, action: PayloadAction<number[]>) {
      state.selected = state.selected.length === action.payload.length ? [] : action.payload;
    },
    clearStatisticsSelected(state) {
      state.selected = [];
    }
  }
});

export const statisticsActions = StatisticsSlice.actions;
export const statisticsReducer = StatisticsSlice.reducer;
