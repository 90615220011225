import { createStyles } from '@mantine/core';

interface FilialProps {
  nestingLevel: number;
}

export const useStyles = createStyles((theme, { nestingLevel = 0 }: FilialProps) => ({
  tr: {
    padding: '0 !important',
    height: '72px',
    position: 'relative',
    backgroundColor: 'white',
    cursor: 'pointer',

    'td:first-of-type': {
      maxWidth: '400px',
      '& .mantine-ActionIcon-root': {
        margin: '0 4px'
      },

      '& .mantine-Text-root': {
        wordBreak: 'break-all',
        width: '100%',
        boxSizing: 'border-box'
      }
    },

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.backgroundGray[6],

      '& .action-icon': {
        opacity: 1
      }
    },

    '& .action-icon': {
      opacity: 0
    }
  },

  td: {
    padding: '17px 16px !important',
    borderTop: `1px solid ${theme.colors.backgroundGray[6]} !important`
  },

  rowSelected: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors.mainBlue[0]
  },

  pinnedCOlumn: {
    fontWeight: 600
  },

  hasChildren: {
    '& td:first-of-type': {
      paddingLeft: `${nestingLevel * 12 - 12}px !important`
    }
  },

  noChildren: {
    '& td:first-of-type': {
      paddingLeft: `${nestingLevel * 12 + 24}px !important`
    }
  },

  contentContainer: {
    width: '90%'
  }
}));
