import {
  Flex,
  Text,
  TextInput,
  Button,
  List,
  Box,
  Tooltip,
  ActionIcon,
  Loader,
  LoadingOverlay,
  UnstyledButton
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { FormErrors, useForm } from '@mantine/form';
import { getValidateErrors } from '@/lib/utils/validation';
import { z } from 'zod';
import { useStyles } from '../styles';
import { IconHelp, IconReload } from '@tabler/icons';
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  useLazyAddDomainQuery,
  useLazyCheckDomainQuery,
  useLazyGetLinkRecordQuery
} from '@/entities/admin-app/email/api';
import { DomainResponse, IDomain, IRecordDetails } from '@/entities/admin-app/email';
import { useAppSelector } from '@/hooks/redux/redux';
import { showNotification } from '@mantine/notifications';

interface IAddDomainModalProps {
  handleClose: () => void;
  setOpenPreviewModal: Dispatch<SetStateAction<boolean>>;
  serverId: number;
  handleChange?: () => void;
  setDomainDetails: Dispatch<SetStateAction<IDomain | IRecordDetails | undefined>>;
}

interface ListItemProps {
  label: string;
  value: string | undefined;
}

export const AddDomainModal = ({
  handleClose,
  setOpenPreviewModal,
  serverId,
  handleChange,
  setDomainDetails
}: IAddDomainModalProps) => {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const fieldName = 'Domain';
  const { filial } = useAppSelector((state) => state.filials);
  const [checkDomainExist, { isLoading, isFetching }] = useLazyCheckDomainQuery();
  const [addDomain, { isLoading: addDomainLoading }] = useLazyAddDomainQuery();
  const [getLinkRecord, { data: linkRecord, isLoading: loadingLinkRecord }] =
    useLazyGetLinkRecordQuery();

  const domainFormSchema = z.object({
    Domain: z.string().regex(/^(?!-)[A-Za-z0-9-]+(\.[a-z0-9]+)*\.[A-Za-z]{2,6}$/, {
      message: t('addDomain.errors.invalidName')
    })
  });

  const domainForm = useForm({
    initialValues: {
      Domain: ''
    },
    validateInputOnChange: true,
    validate: (values: any): FormErrors => getValidateErrors(domainFormSchema, values)
  });

  const errors = domainForm.errors;
  const isDirtyForm = domainForm.isDirty();
  const isDisabled =
    !isDirtyForm || !linkRecord || errors.Domain === t('addDomain.errors.invalidName');

  useEffect(() => {
    handleGetLinkRecord();
  }, []);

  const handleGetLinkRecord = async () => {
    await getLinkRecord({ serverid: serverId });
  };

  const modalClose = () => {
    handleClose();
    domainForm.reset();
  };

  const handleSubmit = async (values: any) => {
    await handleValidateField(values.Domain);
  };

  const handleAddDomain = async () => {
    if (filial) {
      try {
        const res = await addDomain({
          Domain: domainForm.values.Domain,
          ServerId: serverId,
          CustomerId: filial.Id
        });
        if ('error' in res) {
          showNotification({
            title: `${(res as any)?.error?.data}`,
            message: '',
            autoClose: true,
            color: 'red',
            style: { zIndex: 1003 }
          });
        } else {
          showNotification({
            title: t('addDomain.succes'),
            message: '',
            autoClose: true,
            color: 'green',
            style: { zIndex: 1003 }
          });
          modalClose();
          if (handleChange) {
            handleChange();
          }
          setOpenPreviewModal(true);
          setDomainDetails(res.data);
        }
      } catch (err: any) {
        console.warn(t('addDomain.errors.notCreated'), err);
      }
    }
  };

  const handleValidateField = async (domain: string) => {
    if (domain?.length && domainForm.isDirty(fieldName) && filial?.Id && linkRecord) {
      const response = await checkDomainExist({
        domain: domainForm.values.Domain,
        customerId: filial.Id,
        serverId: serverId
      });
      switch (response?.data?.Status) {
        case DomainResponse.SUCCESS:
          await handleAddDomain();
          break;
        case DomainResponse.EXISTS:
          domainForm.setFieldError(fieldName, t('addDomain.errors.exists'));
          break;
        case DomainResponse.NONEXISTS:
          domainForm.setFieldError(fieldName, t('addDomain.errors.nonExists'));
          break;
        case DomainResponse.ERROR:
          domainForm.setFieldError(fieldName, t('addDomain.errors.error'));
          break;
        case DomainResponse.NONEXISTSRECORD:
          domainForm.setFieldError(fieldName, t('addDomain.errors.nonExistsRecord'));
          break;
        default:
          domainForm.setFieldError(fieldName, t('addDomain.errors.error'));
          break;
      }
    }
  };

  const ListItem = ({ label, value }: ListItemProps) => (
    <List.Item>
      <Flex align="center" wrap="wrap">
        <Text size="sm">{label}:&nbsp;</Text>
        <Text fw="bold" size="sm" className={classes.linkValue}>
          {value ? value : <NoRecordLinkElement />}
        </Text>
      </Flex>
    </List.Item>
  );

  const NoRecordLinkElement = () => (
    <Flex align="center">
      {loadingLinkRecord ? (
        <Loader size="xs" />
      ) : (
        <>
          <Text fw="bold">{t('loadingError')}</Text>
          <Tooltip
            sx={{
              background: theme.colors.brandGray[1],
              color: theme.colors.dark,
              borderRadius: theme.spacing.xs,
              padding: '8px'
            }}
            label={t('reload')}
          >
            <ActionIcon variant="transparent" ml="xs" onClick={() => handleGetLinkRecord()}>
              <IconReload size="20" cursor="pointer" />
            </ActionIcon>
          </Tooltip>
        </>
      )}
    </Flex>
  );

  return (
    <>
      <Box p="24px">
        <Text size="sm" mb="sm">
          {t('addDomain.text1')}
        </Text>
        <Text size="sm" mb="xs">
          {t('addDomain.text2')}
        </Text>
        <Box px="sm" pt="sm">
          <Flex>
            <Text> {t('addDomain.text3')}</Text>
            <Tooltip
              sx={{
                background: theme.colors.brandGray[1],
                color: theme.colors.dark,
                borderRadius: theme.spacing.xs,
                padding: '8px',
                width: '500px',
                zIndex: 14001
              }}
              multiline
              position="top-start"
              label={
                <Text size="sm">
                  {t('addDomain.hint1')}
                  <br />
                  {t('addDomain.hint2')}
                </Text>
              }
            >
              <ActionIcon variant="transparent" ml="xs">
                <IconHelp size="20" color="gray" cursor="pointer" />
              </ActionIcon>
            </Tooltip>
          </Flex>
          <List size="sm" pl="sm" pt="sm">
            <ListItem label={t('type')} value="TXT" />
            <ListItem label={t('name')} value="@" />
            <ListItem label={t('domains.textMeaning')} value={linkRecord?.LinkRecord} />
          </List>
        </Box>
      </Box>
      <form onSubmit={domainForm.onSubmit(handleSubmit)}>
        <Box px="24px" pb="24px" mb="70px">
          <Text size="sm" mb="sm">
            {t('addDomain.inputLabel')}
          </Text>
          <TextInput
            className={classes.textFieldInput}
            withAsterisk
            label={t('domains.domain')}
            placeholder="yourcompany.com"
            {...domainForm.getInputProps('Domain')}
          />
          {(isLoading || isFetching) && (
            <Box className={classes.loader}>
              <Loader size="xs" />
              <Text size="sm" ml="xs" color="dimmed">
                Загрузка ...
              </Text>
            </Box>
          )}
        </Box>

        <Box className={classes.modalFooter}>
          <Button w="49%" type="submit" disabled={isDisabled}>
            {t('add')}
          </Button>
          <UnstyledButton w="49%" className={classes.modalCancelBtn} onClick={handleClose}>
            {t('cancel')}
          </UnstyledButton>
        </Box>
      </form>
      <LoadingOverlay visible={addDomainLoading} overlayBlur={2} />
    </>
  );
};
