import { createStyles } from '@mantine/core';
import { modalTheme } from '@components/modal/styles';

export const useStyles = createStyles((theme) => ({
  modalContainer: modalTheme(theme).container,
  modalFooter: { ...modalTheme(theme).footer, position: 'sticky', bottom: 0, zIndex: 1 },
  modalCancelBtn: modalTheme(theme).cancelBtn,

  link: {
    color: theme.colors.blue[9],
    '&:hover': {
      color: theme.colors.blue[4]
    }
  }
}));
