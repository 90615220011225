import { FC, useEffect } from 'react';
import { Button, Flex, Radio, Text, NumberInput } from '@mantine/core';

import { useAppSelector } from '@hooks/redux/redux';
import { SettingsEnum } from '@/containers/pages/filials/utils';
import { useSaveSettings } from '@/containers/pages/filials/hooks/useSaveSettings';

import { FilialInfoPanelsEnum } from '@/types/enums/filials';

import { useStyles } from '../../../../../styles';
import { FormErrors, useForm } from '@mantine/form';
import { z } from 'zod';
import { getValidateErrors } from '@/lib/utils/validation';
import { useTranslation } from 'react-i18next';

export interface IPasswordAttempts {
  count: number;
  limitEnabled: boolean;
}

export const PasswordLimit: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { filial } = useAppSelector((state) => state.filials);
  const defaultCount = 6;

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      count: 6,
      limitEnabled: false
    },
    validate: (values): FormErrors => getValidateErrors(schema, values)
  });

  const schema = z.object({
    count: z.number({ invalid_type_error: t('filialSecurity.errors.passwordAttempts') }).positive({
      message: t('filialSecurity.errors.positive')
    })
  });

  const saveSettings = useSaveSettings();

  const errors = form.errors;
  const isDisabled = Object.keys(errors).length !== 0 || !form.isDirty();

  useEffect(() => {
    const { PassEnterCount } = filial?.Settings || {};
    if (!PassEnterCount) {
      return;
    }

    const formData = {
      count: PassEnterCount.Count || defaultCount,
      limitEnabled: PassEnterCount.Enabled
    };
    form.setValues(formData);
    form.resetDirty(formData);
  }, [filial]);

  const handleSubmit = (values: IPasswordAttempts) => {
    if (!filial) {
      return;
    }

    saveSettings(filial.Id, SettingsEnum.PassEnterCount, {
      PassEnterCount: {
        Enabled: values.limitEnabled,
        Count: values.count
      }
    });
  };

  const handleChange = () => {
    if (!form.values.count) {
      form.setValues({ limitEnabled: false, count: defaultCount });
    } else {
      form.setValues({ limitEnabled: false });
    }
  };

  return (
    <Flex
      className={classes.section}
      align="flex-start"
      direction="column"
      data-section={FilialInfoPanelsEnum.passwordLimit}
    >
      <Text className={classes.title} my="sm">
        {t('filialSecurity.passwordTitle')}
      </Text>

      <Radio
        className={classes.radio}
        size="xs"
        checked={!form.values.limitEnabled}
        onChange={() => handleChange()}
        label={t('disable')}
      />
      <Radio
        className={classes.radio}
        size="xs"
        checked={form.values.limitEnabled}
        onChange={() => form.setValues({ limitEnabled: true })}
        label={t('enable')}
      />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Flex align="flex-start" direction="column">
          {form.values.limitEnabled && (
            <NumberInput
              className={classes.textFieldInput}
              label={t('filialSecurity.passwordLabel')}
              hideControls
              {...form.getInputProps(`count`)}
            />
          )}

          <Text className={classes.description} mt="sm" mb="md">
            {t('filialSecurity.passwordWarning')}
          </Text>

          <Button disabled={isDisabled} size="sm" radius={8} mr="sm" type="submit">
            {t('save')}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
