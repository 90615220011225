import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing.md
  },

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#F9FAFB',
    height: '63px',
    padding: `0px ${theme.spacing.md}`,
    borderTop: '1px solid #F2F4F7'
  },

  cancelBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '8px',
    padding: `8px ${theme.spacing.sm}`,
    backgroundColor: theme.colors.brandGray[1],
    fontSize: '14px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '17px',

    '&:hover': {
      backgroundColor: '#eff0f4'
    },

    '&:active': {
      transform: `translateY(1px)`
    }
  },

  textFieldInput: {
    width: '100%',
    '& .mantine-TextInput-label, .mantine-InputWrapper-label': {
      color: theme.colors.brandGray[0],
      fontSize: '12px'
    },

    '& .mantine-TextInput-input': {
      borderRadius: '8px',
      border: '1px solid #D0D5DD'
    }
  },

  addBtn: {
    marginTop: theme.spacing.sm,
    borderRadius: '8px',
    border: `1px solid ${theme.colors.brandGray[0]}`,

    svg: {
      color: theme.colors.brandGray[0]
    },

    '&:hover': {
      backgroundColor: '#f6f7f8'
    },

    '&:disabled': {
      backgroundColor: '#e9ecef',

      'span, svg': {
        color: '#adb5bd'
      }
    },

    '& .mantine-Button-inner': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },

    '& .mantine-Button-label': {
      color: theme.black
    }
  },

  badge: {
    paddingRight: 3,
    backgroundColor: theme.colors.backgroundGray[6],
    color: 'black',
    borderRadius: '16px',
    marginRight: theme.spacing.xs,
    marginTop: theme.spacing.xs,
    border: 'none',
    textTransform: 'inherit',
    height: '28px',
    fontSize: '14px',
    padding: theme.spacing.xs,
    fontWeight: 400
  }
}));
