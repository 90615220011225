import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tr: {
    padding: '0 !important',
    height: '72px',
    position: 'relative',
    backgroundColor: 'white',
    cursor: 'pointer',

    '&:first-of-type td': {
      borderTop: 'none !important'
    },

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.backgroundGray[6],

      '& .action-icon': {
        opacity: 1
      }
    },

    '& .action-icon': {
      opacity: 0
    }
  },

  td: {
    padding: '17px 24px !important',
    borderTop: `1px solid ${theme.colors.backgroundGray[6]} !important`
  },

  rowSelected: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors.mainBlue[0]
  },

  pinnedRow: {
    backgroundColor: theme.colors.backgroundGray[6],
    fontWeight: 600
  },

  withCheckbox: {
    padding: '17px 16px !important'
  },

  hideColumn: {
    display: 'none'
  },

  pinnedCOlumn: {
    fontWeight: 600
  }
}));
